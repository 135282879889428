import { forwardRef, Inject, Injectable, NgZone } from '@angular/core'
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { catchError, Observable, switchMap, throwError } from 'rxjs'
import { AuthService } from '../auth/auth.service'
import { AccountService } from '../account/account.service'
import { Router } from '@angular/router'
import { DateHelper } from '../../common/static/date.helper'
import { MatDialog } from '@angular/material/dialog'
import {
  LoginTwoFactorModalComponent
} from '../../components/login-two-factor-modal/login-two-factor-modal.component'
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar'
import { TranslateService } from '@ngx-translate/core'

@Injectable()
export class RequestInterceptorService implements HttpInterceptor {

  constructor(
    private router: Router,
    private matDialog: MatDialog,
    private authService: AuthService,
    private accountService: AccountService,
    @Inject(forwardRef(() => MatSnackBar))
    private snackBar: MatSnackBar,
    @Inject(forwardRef(() => NgZone))
    private zone: NgZone,
    private translateService: TranslateService
  ) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    request = this.addAuthHeader(request)
    return next.handle(request).pipe(
      catchError(error => {
        return this.handleResponseError(error, request, next)
      })
    )
  }

  private addAuthHeader(request: HttpRequest<any>): HttpRequest<any> {
    const authHeader: string = this.authService.getSessionKey()
    const currentCompanyId: string | undefined = this.accountService.getCurrentCompanyId()
    const accountTimezone = this.accountService.account$.getValue()?.timezone
    const staticTimezoneON = this.accountService.account$.getValue()?.staticTimezoneON
    if (authHeader && currentCompanyId) {
      return request.clone({
        setHeaders: {
          'Authorization': `Bearer ${ authHeader }`,
          'x-company-id': currentCompanyId,
          'x-user-timezone': (staticTimezoneON ? accountTimezone : DateHelper.getTimezone()) || 'Europe/Kiev'
        }
      })
    } else if (authHeader) {
      return request.clone({
        setHeaders: {
          'Authorization': `Bearer ${ authHeader }`,
          'x-user-timezone': (staticTimezoneON ? accountTimezone : DateHelper.getTimezone()) || 'Europe/Kiev'
        }
      })
    }
    return request
  }

  private handleResponseError(error: HttpErrorResponse, request: HttpRequest<any>, next: any) {
    const errorCode: string | undefined = error.error?.code
    if (error.status === 401 && errorCode === 'acOTPR') {
      return this.matDialog.open(LoginTwoFactorModalComponent, {
        autoFocus: false,
        maxWidth: '420px',
        width: '100%'
      }).afterClosed().pipe(
        switchMap((otp: string | null) => {
          if (otp) {
            const clonedRequest = request.clone({
              body: { ...request.body, otp }
            })
            return next.handle(clonedRequest)
          } else {
            this.showError(error)
            return throwError(() => error)
          }
        })
      )
    } else if (error.status === 401 && errorCode === 'acOTPIC') {
      this.showError(error)
      return throwError(() => error)
    } else {
      if (error.status === 401) {
        this.authService.logout()
      } else if (error.status === 403) {
        this.authService.logout()
      } else if (error.status === 404) {
        this.router.navigate(['/'])
      }
      this.showError(error)
      return throwError(() => error)
    }
  }

  private showError(error: HttpErrorResponse): void {
    const message = error.error?.message || this.translateService.instant('errors.occurred')
    this.zone.run(() => {
      this.snackBar.open(message, '✕', {
        duration: 10000,
        panelClass: 'error',
        verticalPosition: 'bottom',
        horizontalPosition: 'end'
      })
    })
  }


}
