export const svgIconNames: string[] = [
  "box",
  "pie-chart",
  "group",
  "thunder-move",
  "thunder",
  "dollar",
  "key",
  "square-group",
  "layout-range",
  "pantone",
  "notifications",
  "angle-double-left",
  "angle-double-right",
  "globe",
  "calendar",
  "search",
  "flag",
  "add-user",
  "reload",
  "undo",
  "export",
  "warning",
  "error",
  "success",
  "info",
  "close",
  "attachment",
  "attachment-2",
  "fullName",
  "user",
  "clipboard",
  "layout-panel",
  "template",
  "time-schedule",
  "check",
  "email",
  "phone",
  "password",
  "star",
  "hub",
  "trash",
  "building",
  "bulb",
  "barcode",
  "earth",
  "integration",
  "edit",
  "spy",
  "write",
  "pave",
  "archive",
  "lock",
  "chart-bar-1",
  "code",
  "download-file",
  "settings",
  "chat-group",
  "expand-arrows",
  "diagnostic",
  "filter",
  "play-circle",
  "percentage-tag",
  "cross-circle",
  "done-circle",
  "robot",
  "lighting-letter",
  "plane-move",
  "money",
  "stop",
  "fan",
  "lte",
  "marker",
  "shield-check",
  "chat",
  "layout-top-panel",
  "up-down",
  "brush",
  "github",
  "shutdown",
  "save",
  "lock-overturning",
  "delete-user",
  "copy",
  "shield"
];
