import { Inject, Injectable } from '@angular/core'
import { BehaviorSubject, map, Observable } from 'rxjs'
import { IAccountModel, IAccountRoleModel } from '../../common/models/domain/models'
import { ICompany, ICompanyModel } from '../../common/models/domain/models/company.model'
import { LOCAL_STORAGE_KEYS } from '../local-storage/local-storage.key'
import { LocalStorageService } from '../local-storage/local-storage.service'
import { DateHelper } from '../../common/static/date.helper'
import { MetricConfigModel } from '../../common/models/domain/models/metricConfig.model'

@Injectable()
export class AccountService {
  public account$: BehaviorSubject<Partial<IAccountModel> | undefined> = new BehaviorSubject<Partial<IAccountModel> | undefined>(undefined)
  public companyLogoUrl: string

  constructor(
    @Inject(Window)
    private readonly windowRef: Window,
    private readonly localStorage: LocalStorageService
  ) {
    this.companyLogoUrl = `https://storage.googleapis.com/${ this.getBucket() }/logos/${ this.getCompanyDomain() }`
  }

  private getBucket(): string {
    switch (this.windowRef.location.hostname) {
      case 'localhost':
      case 'dev-client.hypernet.pro':
        return 'htn-public-dev'
      default:
        return 'htn-public'
    }
  }

  private getCompanyDomain(): string {
    switch (this.windowRef.location.hostname) {
      case 'localhost':
        return 'dev-secured.hypernet.pro'
      default:
        return this.windowRef.location.hostname
    }
  }

  public get subscriptionId$(): Observable<string | undefined> {
    return this.account$.pipe(
      map((a: Partial<IAccountModel> | undefined) => a?.subscription?.id)
    )
  }

  public get companiesCount$(): Observable<number> {
    return this.account$.pipe(
      map((a: Partial<IAccountModel> | undefined) => a?.companies?.length || 0)
    )
  }

  public get currentCompany$(): Observable<ICompany | undefined> {
    return this.account$
      .pipe(
        map((a: Partial<IAccountModel> | undefined) => a?.currentCompany)
      )
  }

  public get mfaON$(): Observable<boolean> {
    return this.account$
      .pipe(
        map((a: Partial<IAccountModel> | undefined) => !!a?.mfaON)
      )
  }

  public getCurrentCompany(): ICompanyModel | undefined {
    return this.account$?.getValue()?.currentCompany
  }

  public getSubscriptionId(): string | undefined {
    return this.account$?.getValue()?.subscription?.id
  }

  public getAccountId(): string | undefined {
    return this.account$?.getValue()?.id
  }

  public getCurrentCompanyId(): string | undefined {
    return this.getCurrentCompany()?.id
  }

  public get accountFullName(): string | undefined {
    return `${ this.account$?.getValue()?.firstName } ${ this.account$?.getValue()?.lastName }`
  }

  public get accountEmail(): string | undefined {
    return this.account$?.getValue()?.email
  }

  public isStaticTimezone(): boolean {
    return !!this.account$?.getValue()?.staticTimezoneON
  }

  public getAccountTimeZone(): string | undefined {
    return this.account$?.getValue()?.timezone
  }

  public isSafeUsageModeON(): boolean {
    const account: Partial<IAccountModel> | undefined = this.account$.getValue()
    return !!account?.roles?.find((role: IAccountRoleModel) => {
      return role.companyId === account?.currentCompany?.id
    })?.safeUsageModeON
  }

  public patchSafeUsageMode(value: boolean): void {
    const account: Partial<IAccountModel> | undefined = this.account$.getValue()
    const matchRole: IAccountRoleModel | undefined = account?.roles?.find((role: IAccountRoleModel) => {
      return role.companyId === account?.currentCompany?.id
    })
    if (matchRole) {
      matchRole.safeUsageModeON = value
      this.account$.next(account)
    }
  }

  public get timezone$(): Observable<string> {
    return this.account$.pipe(
      map((account: Partial<IAccountModel> | undefined): string => {
        return (account && account.staticTimezoneON
          ? account.timezone
          : DateHelper.getTimezone()) || 'Europe/Kiev'
      })
    )
  }

  public changeCompany(company: ICompanyModel): void {
    /*this.account$.next({
      ...this.account$.value,
      currentCompany: company
    });*/
    this.localStorage.setItem(LOCAL_STORAGE_KEYS.COMPANY, company)
    this.windowRef.location.reload()
  }

  public get showAccountTimezone$(): Observable<boolean | undefined> {
    return this.account$.pipe(
      map((a: Partial<IAccountModel> | undefined) => a?.displayTimeInHeader)
    )
  }

  public updateAccount(timezone: string | undefined, staticTimezoneON: boolean, displayTimeInHeader: boolean): void {
    this.account$.next({
      ...this.account$.getValue(),
      timezone,
      staticTimezoneON,
      displayTimeInHeader
    })
  }

  public patchAccount(account: Partial<IAccountModel>): void {
    this.account$.next({
      ...this.account$.getValue(),
      ...account
    })
  }

  public updateDashboardConfig(dashboardConfig: MetricConfigModel): void {
    this.localStorage.setItem(LOCAL_STORAGE_KEYS.METRICS_CONFIG, dashboardConfig)
  }

}
