import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { AppComponent } from './app.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppRoutingModule } from './app-routing.module'
import { NotFoundComponent } from './components/not-found/not-found.component'
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { ToastModule } from './services/toast/toast.module'
import { I18nService } from './services/i18n/i18n.service'
import { ApiService } from './services/api/api.service'
import { MapService } from './services/map/map.service'
import { registerLocaleData, ViewportScroller } from '@angular/common'
import { RequestInterceptorService } from './services/request-interceptor/request-interceptor.service'
import { AuthService } from './services/auth/auth.service'
import { GlobalErrorHandler } from './services/global-error-handle/global-error-handler.service'
import { IconsService } from './services/icons/icons.service'
import localeUk from '@angular/common/locales/uk'
import localeRu from '@angular/common/locales/ru'
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'
import { RoleService } from './services/role/role.service'
import { AccountService } from './services/account/account.service'
import { Router, Scroll } from '@angular/router'
import { filter, pairwise } from 'rxjs'
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog'
import { AppInitService } from './services/app-init/app-init.service'
import packageJson from 'package.json'
import { MissingTranslationService } from './services/i18n/i18n-missing-translations.service'
import { LocalStorageService } from './services/local-storage/local-storage.service'
import { LoginTwoFactorModalComponent } from './components/login-two-factor-modal/login-two-factor-modal.component'

export function HttpLoaderFactory(http: HttpClient) {
  const verSuffix: string | number = packageJson && packageJson.version ? packageJson.version.replace(/\D/g, '') : Date.now()
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + verSuffix)
}

export function appInitService(appInitService: AppInitService) {
  return () => appInitService.init()
}

registerLocaleData(localeUk, 'uk')
registerLocaleData(localeRu, 'ru')

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient] },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MissingTranslationService },
    }),
    AppRoutingModule,
    ToastModule,
    MatButtonModule,
    MatDialogModule,
    LoginTwoFactorModalComponent
  ],
  providers: [
    I18nService,
    ApiService,
    AuthService,
    AccountService,
    RoleService,
    MapService,
    IconsService,
    AppInitService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: LOCALE_ID,
      deps: [I18nService],
      useFactory: (i18nService: any) => {
        return i18nService.getLanguage()
      }
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitService,
      deps: [AppInitService],
      multi: true
    },
    {
      provide: Window,
      useValue: window
    },
    LocalStorageService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller
  ) {
    this.router.events.pipe(
      filter((e): e is Scroll => e instanceof Scroll),
      pairwise()
    ).subscribe((eventPair: [Scroll, Scroll]) => {
      const previousEvent: any = eventPair[0]
      const event: any = eventPair[1]
      if (event.position) {
        this.viewportScroller.scrollToPosition(event.position)
      } else if (event.anchor) {
        this.viewportScroller.scrollToAnchor(event.anchor)
      } else {
        if ((previousEvent.routerEvent.urlAfterRedirects.split('?')[0]) !== event.routerEvent.urlAfterRedirects.split('?')[0]) {
          this.viewportScroller.scrollToPosition([0, 0])
        }
      }
    })
  }
}
