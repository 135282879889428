import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core'
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog'
import { CodeInputComponent, CodeInputModule } from 'angular-code-input'
import { MatIconModule } from '@angular/material/icon'
import { MatLegacyButtonModule } from '@angular/material/legacy-button'
import { TranslateModule } from '@ngx-translate/core'

@Component({
  selector: 'app-login-two-factor-modal',
  templateUrl: './login-two-factor-modal.component.html',
  styleUrls: ['./login-two-factor-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatDialogModule,
    CodeInputModule,
    MatIconModule,
    MatLegacyButtonModule,
    TranslateModule
  ]
})
export class LoginTwoFactorModalComponent implements AfterViewInit {
  @ViewChild(CodeInputComponent)
  protected readonly codeInput: CodeInputComponent

  protected readonly codeLength: number = 6

  constructor(
    private readonly dialogRef: MatDialogRef<LoginTwoFactorModalComponent>
  ) {
    this.dialogRef.disableClose = true
  }

  protected codeCompleted(code: string): void {
    this.dialogRef.close(code)
  }

  public ngAfterViewInit(): void {
    this.codeInput.focusOnField(0)
  }

}
