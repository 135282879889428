import { ErrorHandler, forwardRef, Inject, Injectable, NgZone } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar'
import { HttpErrorResponse } from '@angular/common/http'

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {

  constructor(
    private translateService: TranslateService,
    @Inject(Window) private windowRef: Window,
    @Inject(forwardRef(() => MatSnackBar))
    private snackBar: MatSnackBar,
    @Inject(forwardRef(() => NgZone))
    private zone: NgZone
  ) {
    super()
  }

  private showError(message: string): void {
    this.zone.run(() => {
      this.snackBar.open(message, '✕', {
        duration: 10000,
        panelClass: 'error',
        verticalPosition: 'bottom',
        horizontalPosition: 'end'
      })
    })
  }

  public override handleError(error: any): void {
    if (error instanceof Error || error instanceof HttpErrorResponse) {
      if (/ChunkLoadError/.test(error?.message)) {
        if (this.windowRef.navigator.onLine) {
          this.showError(this.translateService.instant('errors.serviceUnavailable'))
        } else {
          this.showError(this.translateService.instant('errors.connectionLost'))
        }
      } else {
        const isAboutSvgIcon = !!error.message.match('Unable to find icon with the name')
        const isAboutCdr = !!error.message.match('ExpressionChangedAfterItHasBeenCheckedError')
        !isAboutCdr && !isAboutSvgIcon && this.showError(error?.['error']?.message || error?.message || this.translateService.instant('errors.occurred'))
      }
      return
    }
    super.handleError(error)
  }

}
