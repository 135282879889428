<div mat-dialog-content>
  <div class="icon-wrapper">
    <mat-icon svgIcon="lock"></mat-icon>
  </div>
  <h3>{{'mfa.login.title' | translate}}</h3>
  <p>{{'mfa.login.p' | translate}}</p>
  <div class="code-input-wrapper">
    <code-input
      [isCodeHidden]="false"
      [codeLength]="codeLength"
      (codeCompleted)="codeCompleted($event)">
    </code-input>
  </div>
  <button
    mat-flat-button
    color="accent"
    class="w-100 small mb-2"
    type="submit"
    [mat-dialog-close]="false">
    {{'common.cancel' | translate}}
  </button>
</div>
