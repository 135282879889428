import { Injectable } from '@angular/core'
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar'
import { TranslateService } from '@ngx-translate/core'
import { MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar'
import { ToastMsgModel } from './models/toast-msg.model'
import { ToastLoaderComponent } from './components/toast-loader/toast-loader.component'

@Injectable()
export class ToastService {
  public readonly defaultDuration: number = 3000

  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly translateService: TranslateService
  ) {
  }

  public show(params: Partial<ToastMsgModel>): MatSnackBarRef<TextOnlySnackBar> {
    const message: string = this.translateService.instant(params.i18nKey || 'errors.occurred', params.i18nInterpolateParams)
    const action: string | undefined = params.dismissible ? '✕' : undefined
    return this.snackBar.open(message, action, {
      duration: params.duration || this.defaultDuration,
      panelClass: params.type || 'info',
      verticalPosition: 'bottom',
      horizontalPosition: 'end'
    })
  }

  public showLoader(params: Partial<ToastMsgModel>): MatSnackBarRef<ToastLoaderComponent> {
    const message: string = this.translateService.instant(params.i18nKey || 'common.loading', params.i18nInterpolateParams)
    return this.snackBar.openFromComponent(ToastLoaderComponent, {
      panelClass: params.type || 'info',
      verticalPosition: 'bottom',
      horizontalPosition: 'end',
      data: message
    })
  }

  public close(): void {
    this.snackBar.dismiss()
  }

}
